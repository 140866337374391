import React,{ 
    forwardRef,
    useRef,
    useEffect,
    useState
} from 'react'
import {
    Input,
    Icon,
    Button
} from "antd";
import './index.less'

const Index = forwardRef((props,ref)=>{
    let {dataSource,value=[],onChange=([])=>{}} = props
    const popRef = useRef()
    const selectRef1 = useRef()
    const selectRef2 = useRef()
    let [show,setShow] = useState(false)
    let [keyword,setKeyword] = useState('')
    
    const init = ()=>{
        let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        let right = width - popRef.current.getBoundingClientRect().right
        if(right < 0){
            popRef.current.style.left = 'unset'
            popRef.current.style.right = 0
        }else{
            popRef.current.style.right = 'unset'
            popRef.current.style.left = 0
        }
    }
    useEffect(()=>{
        init()
        window.addEventListener('resize', () => {
            init()
        });
    },[])
    const handleClear = (e)=>{
        if(e){
            e.preventDefault()
            e.stopPropagation()
        }
        onChange([])
    }
    const hanleFocus = ()=>{
        setShow(true)
    }
    const handleBlur = ()=>{
        setShow(false)
    }
    const hanleInputFocus = ()=>{
        setShow(true)
        setKeyword('')
        selectRef1.current.scrollTop = 0
        selectRef2.current.scrollTop = 0
    }
    const handleSearchChange = (e)=>{
        const {value} = e.target
        setKeyword(value)
    }
    const handleSelect = (item)=>{
        if(!value.includes(item.key)){
            onChange([...value,item.key])
            setTimeout(()=>{
                selectRef2.current.scrollTop = selectRef2.current.scrollHeight
            },30)
        }else{
            let arr = value.filter(key=>key != item.key)
            onChange([...arr])
        }
    }
    const handleDelete = (item)=>{
        let arr = value.filter(key=>key != item.key)
        onChange([...arr])
    }
    return (
        <div className="input_wrap" onBlur={handleBlur} tabIndex="1">
            <Input
            className="input_self"
            readOnly
            onFocus={hanleInputFocus}
            value={value.length ? `已选${value.length}项` : ''} 
            placeholder="请选择" 
            suffix={
                value.length ? 
                <Icon className="input_self_icon" onClick={handleClear} type="close-circle" theme="filled" style={{color: 'rgba(0, 0, 0, 0.25)'}} />
                :
                <Icon className="input_self_icon" type="down" style={{color: 'rgba(0, 0, 0, 0.25)'}} />
            }/>
            <div className="input_box" onFocus={hanleFocus} tabIndex="1" style={{zIndex: show ? 10 : -100}}>
                <div className='input_pop' ref={popRef}>
                    <div className='input_pop_content'>
                        <div className='input_pop_content_left'>
                            <Input
                            value={keyword} 
                            onChange={handleSearchChange}
                            placeholder="搜索关键字" />
                            <div className='select_content' ref={selectRef1}>
                                {
                                    dataSource.map(item=>(
                                        item.title.includes(keyword) ? 
                                        <div className={'select_content_item ' + (value.includes(item.key) ? 'select_content_item_active' : '')} onClick={()=>handleSelect(item)} key={item.key}>{item.title}</div>
                                        : 
                                        null
                                    ))
                                }
                            </div>
                        </div>
                        <div className='input_pop_content_right'>
                            <div className='select_content_header'>
                                已选{value.length}项
                            </div>
                            <div className='select_content' ref={selectRef2}>
                                {
                                    dataSource.map(item=>(
                                        value.includes(item.key) ?
                                        <div className='select_content_item select_content_item_active' key={item.key}>
                                            {item.title}
                                            <span className="del_icon">
                                                <Icon onClick={()=>handleDelete(item)} type="close-circle" theme="filled" style={{color: 'rgba(0, 0, 0, 0.25)'}} />
                                            </span>
                                        </div>
                                        : 
                                        null
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className='select_footer'>
                        <Button type="primary" size="small" onClick={handleBlur}>确定</Button>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default Index