import React, { useEffect,useState } from 'react';
import './index.less'
const one = [
    {
        title: '环境氛围',
        des: '店内装修独具特色，现代简约与传统火锅元素完美融合。宽敞明亮的空间，舒适的座椅，让你可以尽情享受美食时光。暖色调的灯光营造出温馨而热烈的氛围，无论是与家人团聚、公司团建、朋友聚会还是商务宴请，这里都是绝佳的选择。',
        pic: '04'
    },
    {
        title: '锅底特色',
        des: '我们提供多种口味的锅底，满足不同食客的需求。经典的麻辣锅底，精选上等辣椒和花椒，辣而不燥，麻味醇厚，让你舌尖瞬间燃烧起来。鲜香的清汤锅底，以大骨熬制，汤汁浓郁，营养丰富。还有特色番茄锅底、菌汤锅底等，每一种都独具风味。',
        pic: '06'
    },
    {
        title: '菜品丰富',
        des: '新鲜的食材是我们的承诺。各种优质肉类，如嫩牛肉、羊肉卷、五花肉等，纹理清晰，肉质鲜嫩多汁。海鲜类产品种类繁多，鲜虾、贝类、鱼丸等，保证新鲜美味。蔬菜更是琳琅满目，绿叶蔬菜、菌菇类、豆制品等，为你的火锅增添丰富口感。',
        pic: '08'
    },
]
const two = [
    {
        title: '锅底特色',
        des: '独家秘制配方我们的火锅店拥有独特的锅底配方，由经验丰富的厨师团队经过长时间的研发和调试而成。',
        list: [
            {
                pic: '14',
                title: '麻辣锅底：',
                des: '选用多种优质辣椒和花椒，精心炒制，辣而不燥，香气扑鼻。独特的香料搭配，让辣味层次更加丰富，让你在舌尖上感受一场热辣风暴。'
            },
            {
                pic: '18',
                title: '清汤锅底：',
                des: '以鲜美的大骨汤为基础，加入多种滋补食材，如枸杞、红枣、党参等，营养丰富，味道醇厚。适合不能吃辣的顾客，也可以在品尝完辣锅后，用清汤锅底缓解辣味，平衡口感。'
            },
            {
                pic: '20',
                title: '特色鸳鸯锅：',
                des: '满足不同口味需求，一边是热辣过瘾的麻辣锅底，一边是清淡鲜美的清汤锅底，让你可以同时享受两种不同的美味。'
            },
        ]
    },
    {
        title: '菜品特色',
        list: [
            {
                pic: '22',
                title: '新鲜食材：',
                des: '我们坚持使用新鲜的食材，每天从市场采购最新鲜的蔬菜、肉类和海鲜。所有食材都经过严格的筛选和检验，确保品质安全。我们的蔬菜鲜嫩多汁，肉类纹理清晰，海鲜活蹦乱跳，让你吃得放心，吃得健康。'
            },
            {
                pic: '33',
                title: '特色牛肉：',
                des: '我们的牛肉选用上等的牛肉部位，如嫩牛肉、雪花牛肉等，肉质鲜嫩多汁，口感醇厚。经过特殊的腌制处理，更加入味，让你在品尝火锅的同时，也能享受到美味的牛肉。'
            },
            {
                pic: '32',
                title: '海鲜拼盘：',
                des: '我们的海鲜拼盘包含各种新鲜的海鲜，如虾、蟹、贝类等。海鲜都是当天从海鲜市场采购而来，保证新鲜度。在火锅中煮熟后，海鲜的鲜美与火锅的味道相互融合，让你回味无穷。'
            },
            {
                pic: '31',
                title: '蔬菜拼盘：',
                des: '我们的蔬菜拼盘包含多种新鲜的蔬菜，如青菜、豆芽、蘑菇等。蔬菜都是当天从市场采购而来，保证新鲜度。在火锅中煮熟后，蔬菜的清爽与火锅的味道相互搭配，让你吃得健康。'
            },
            {
                pic: '30',
                title: '火锅串串：',
                des: '将各种食材串在竹签上，放入火锅中煮熟后食用。火锅串串既方便又有趣，可以让你品尝到更多不同的食材。'
            },
            {
                pic: '34',
                title: '特色小吃：',
                des: '我们的火锅店还提供各种特色小吃，如炸酥肉、红糖糍粑、冰粉等。这些小吃都是我们的厨师精心制作而成，口感鲜美，让你在品尝火锅的同时，也能享受到美味的小吃。'
            },
        ]
    }
]
const Index = ()=> {
    const [mobile,setMobile] = useState(false)
    useEffect(()=>{
        window.document.title = '惠州顶顶火锅店'
        console.log(window.innerWidth)
        if(window.innerWidth <=768){
            console.log('fs')
            setMobile(true)
        }
    })
    return (<div className='wrap'>
        <div className='warp_header'>
            <img src={require(`@/statics/img/website/header.jpeg`)} />
            <div className='warp_header_cover'>
                <div className={'warp_header_cover_title ' + (mobile ? 'warp_header_cover_title_mobile' : '')}>惠州顶顶火锅店</div>
                <div className={'warp_header_cover_des ' + (mobile ? 'warp_header_cover_des_mobile' : '')}>一场热辣鲜香的美食之旅就此开启</div>
            </div>
        </div>
        <div className='warp_content'>
            <div className='one_wrap'>
                {
                    one.map((item,index)=>(
                        <div className='one_item' key={'one'+index}>
                            <div className='one_item_header'>
                                {item.title}
                            </div>
                            <div className='one_item_des'>
                                {item.des}
                            </div>
                            <div className='one_item_pic'>
                                {item.pic ? <img src={require(`@/statics/img/website/w-2_${item.pic}.png`)} /> : null}
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className='two_wrap'>
                {
                    two.map((item,i)=>(
                        <div className='two_item' key={'x'+i}>
                            <div className='two_item_header'>
                                {item.title}
                            </div>
                            {
                                item.des ? 
                                <div className='two_item_des'>
                                    {item.des}
                                </div>
                                : null
                            }
                            <div className='two_content'>
                                {
                                    item.list.map((row,ind)=>(
                                        <div className='two_content_item' key={'two'+ind}>
                                            <div className='two_content_item_pic'>
                                                {row.pic ? <img src={require(`@/statics/img/website/w-2_${row.pic}.png`)} /> : null}
                                            </div>
                                            <div className='two_content_item_right'>
                                                <label>{row.title}</label>
                                                <div className='two_content_item_des'>{row.des}</div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
        <div className='footer'>
            联系电话：400 081 1818
        </div>
    </div>)
}

export default Index