import React, { useEffect, useState, useRef } from "react";
import CommonTable from "@/components/commonTable";
import { Row, Col, Tooltip, Icon } from "antd";
import { Link } from "react-router-dom";
import SearchBox from "./components/searchBox";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import {
  _dayOverdueReports,
  _overdueReportsStatistics,
} from "@/statics/js/api";
import "../style.less";
const DailyPayment = (props) => {
  const tableRef = useRef();
  const [overdueDate, setOverdueDate] = useState({
    overdueOrderCount: 0,
    overdueTotalAmount: 0,
  });
  useEffect(() => {
    let fid =
      localStorage.getItem("type") !== "0" ? localStorage.getItem("fid") : "";
    getOverdueDate({ fid });
  }, []);
  let columns = [
    {
      title: "订单号",
      width: 180,
      dataIndex: "orderSn",
      render: (text) => (
        <Link target="_blank" to={"/packageOrderManagement?orderSn=" + text}>
          {text}
        </Link>
      ),
    },
    {
      title: "商户名",
      width: 160,
      dataIndex: "franchiseeName",
    },
    {
      title: "站点",
      width: 150,
      dataIndex: "siteName",
    },
    {
      title: "月期数",
      width: 80,
      dataIndex: "period",
    },
    {
      title: "是否挂起",
      width: 90,
      dataIndex: "hangUp",
      render: (text) => (text === 0 ? "否" : text === 1 ? "是" : null),
    },
    {
      title: "挂起订单时间",
      width: 100,
      dataIndex: "hangUpTime",
    },
    {
      title: "总日扣期数",
      width: 80,
      dataIndex: "periodsNum",
    },
    {
      title: "已支付期数",
      width: 80,
      dataIndex: "paidPeriods",
    },
    {
      title: () => (
        <>
          <span>已支付租金</span>&nbsp;
          <Tooltip placement="top" title="该订单累计收到的租金">
            <Icon type="question-circle" />
          </Tooltip>
        </>
      ),
      width: 80,
      dataIndex: "paidRentMoneyFormat",
    },
    {
      title: "订单日逾期开始时间",
      width: 110,
      dataIndex: "overdueStartTime",
    },
    {
      title: "已逾期期数",
      width: 80,
      dataIndex: "overduePeriods",
    },
    {
      title: () => (
        <>
          <span>应收未收逾期金额</span>&nbsp;
          <Tooltip placement="top" title="已到支付时间未支付的日扣订单金额">
            <Icon type="question-circle" />
          </Tooltip>
        </>
      ),
      width: 110,
      dataIndex: "unpaidOverdueMoney",
    },
    {
      hide: localStorage.getItem("type") !== "0",
      title: "追偿金额",
      width: 100,
      dataIndex: "recourseMoneyFormat",
    },
    {
      title: "套餐名称",
      width: 150,
      dataIndex: "packageName",
    },
    {
      title: "套餐类型",
      width: 90,
      dataIndex: "packagesType",
      render: (text) =>
        text === 1
          ? "租赁"
          : text === 2
          ? "销售"
          : text === 3
          ? "以租代售"
          : text === 4
          ? "连续租赁"
          : null,
    },
    {
      title: "先付/后付",
      width: 100,
      dataIndex: "deductType",
      render: (text) =>
        text === 0 ? "--" : text === 1 ? "先付" : text === 2 ? "后付" : null,
    },
    {
      title: "套餐金额",
      width: 90,
      dataIndex: "packageMoneyFormat",
    },
    {
      title: "月应扣金额",
      width: 80,
      dataIndex: "periodPaidMoneyFormat",
    },
    {
      title: "每日应扣款金额",
      width: 100,
      dataIndex: "dayPaidMoneyFormat",
    },
    {
      title: "订单下单时间",
      width: 100,
      dataIndex: "createTime",
    },
    {
      title: "用户姓名",
      width: 90,
      dataIndex: "username",
    },
    {
      title: "手机号",
      width: 110,
      dataIndex: "mobile",
    },
    {
      title: "身份证号码",
      width: 120,
      dataIndex: "idCard",
    },
    {
      title: "数据类型",
      width: 100,
      dataIndex: "errorData",
      render: (text) =>
        text === 0 ? "正常数据" : text === 1 ? "异常数据"  : null,
    },
    {
      title: "备注",
      width: 100,
      dataIndex: "remark",
    },
  ];
  columns = columns.filter((item) => !item.hide);
  const getOverdueDate = async ({ fid = "" }) => {
    let params = { fid, type: 1 };
    const res = await _overdueReportsStatistics(params);
    if (res.data.code === 200) {
      setOverdueDate(res.data.data);
    }
  };
  const handleSearch = (params) => {
    tableRef.current.handleSearch(params);
    getOverdueDate({ fid: params.fid });
  };
  return !getJurisdiction(1109) ? (
    "暂无操作权限！"
  ) : (
    <div>
      <Row gutter={16}>
        <Col span={12}>
          <div className="centerDiv">
            总逾期订单数（近1年）
            <span>{overdueDate.overdueOrderCount}</span>
            笔
          </div>
        </Col>
        <Col span={12}>
          <div className="centerDiv">
            总逾期金额（近1年）
            <span>{overdueDate.overdueTotalAmountFormat}</span>
            元
          </div>
        </Col>
      </Row>
      <div className="select-form">
        <SearchBox search={handleSearch} />
      </div>
      <CommonTable
        action={_dayOverdueReports}
        showSort
        options={{
          columns: columns,
          scroll: { x: 2500, y: 500 },
        }}
        ref={tableRef}
      />
    </div>
  );
};

export default DailyPayment;
