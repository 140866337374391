import React, { Component, createRef } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Table,
  Switch,
  Divider,
  Modal,
  message,
  Input,
  Tabs,
  Spin,
  Radio,
  Select,
  Form,
  DatePicker,
  Row,
  Col,
} from "antd";
import {
  _deviceChangeList,
  _bikeDetail,
  _bikeOrderList,
  _bikeModelList,
  _merchantBikeModels,
  _exportBikeList,
  _franchiseeList,
  _getQRCode,
  _getBikeStatus,
  _sendInstructionBike,
  _devicePositionBike,
  _bikeRepairLogList,
  _updateBikeStatus,
  _bikeList,
  _saveBikeInfo,
  _batchImportBike,
  _deleteBike,
  _siteBoxList,
  _insertTransfer,
  _deviceUseLog,
  _kaBikeTransferList,
} from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import { Map, Marker, InfoWindow } from "react-amap";
import AMapLoader from "@amap/amap-jsapi-loader";
import moment from "moment";
import BindUser from "./components/bindUser/index";
import BackUser from "./components/backUser/index";
import TransferModal from "./components/transferModal/index";
import ImportBike from "./components/importBike/index";
import EditBike from "./components/editBike/index";
import BikeStatistic from "./bikeStatistic/index";
import "./style/index.less";
import queryString from 'query-string';
const amapkey = "4792ad0f37a527c1535e83599762d894";
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

class BikeManagement extends Component {
  constructor(props) {
    super(props);
    const { useOrder, status, fid } =  queryString.parse(this.props.location.search)
    this.state = {
      queryFormValues: {
        useOrder: useOrder,
        fid: fid
          ? Number(fid)
          : undefined,
        status: status
          ? status
          : "1",
      },
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      bikeList: [], //车辆管理列表
      franchiseeList: [], //代理商列表
      bikeModelList: [], //车辆型号列表
      initialValues: {}, //车辆详情
      siteList: [], //站点列表
      bikeOrderList: [], //车辆订单列表
      useLogList: [], //使用记录
      transferLogList: [], //调拨记录
      id: "", //车辆id
      visibleDetail: false, //Modal是否打开（详情）
      selectedData: [], //选中车架号编号
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      userType: localStorage.getItem("type"),   
      userVisible: false,
      backVisible: false,
      curId: "",
      previewVisible: false,
      imgList: [],
      statisticVisible: false,
      curImei: "",
    };
    this.transferRef = createRef();
    this.importRef = createRef();
    this.editRef = createRef();
    const { userType } = this.state;
    this.columns = [
      {
        title: "序号",
        dataIndex: "no",
        width: 80,
        render: (text, record, index) =>
          this.state.pageSize * (this.state.curPage - 1) + index + 1,
      },
      {
        title: "车辆信息",
        dataIndex: "",
        width: 230,
        render: (text) =>
          text && (
            <span>
              {text.checkBikeId && "车架号：" + text.checkBikeId}
              {text.checkBikeId && <br />}
              {text.checkCCodeId && "GPS号：" + text.checkCCodeId}
              {text.checkCCodeId && <br />}
              {text.imei && "二维码：" + text.imei}
              {text.imei && <br />}
              {text.vin && "车牌号：" + text.vin}
            </span>
          ),
      },
      {
        title: "代理商",
        dataIndex: "",
        render: (text) => (
          <span>
            {text.fname}
            <br />
            {text.sname}
            <br />
            {text.rname}
          </span>
        ),
      },
      {
        title: "车辆型号",
        dataIndex: "modelName",
      },
      // {
      //   hide: userType !== "0" && userType !== "8" && userType !== "9",
      //   title: "设备状态",
      //   dataIndex: "",
      //   render: (text) => text.bindInfo && text.bindInfo.useStatus,
      // },
      // {
      //   hide: userType !== "0" && userType !== "8" && userType !== "9",
      //   title: "使用人",
      //   dataIndex: "",
      //   render: (text) => (
      //     <div>
      //       <div>{text.bindInfo && text.bindInfo.userName}</div>
      //       <div>{text.bindInfo && text.bindInfo.userMobile}</div>
      //     </div>
      //   ),
      // },
      // {
      //   hide: userType !== "0" && userType !== "8" && userType !== "9",
      //   title: "使用时长",
      //   dataIndex: "",
      //   render: (text) => text.bindInfo && text.bindInfo.useDay + "（天）",
      // },
      // {
      //   hide: userType !== "0" && userType !== "8" && userType !== "9",
      //   title: "使用单号",
      //   dataIndex: "",
      //   render: (text) => text.bindInfo && text.bindInfo.useNo,
      // },
      {
        title: "使用订单",
        align: "right",
        width: 180,
        dataIndex: "orderSn",
        render: (text) => (
          <Link target="_blank" to={"/packageOrderManagement?orderSn=" + text}>
            {text}
          </Link>
        ),
      },
      {
        title: "车辆状态",
        dataIndex: "",
        width: 120,
        render: (text) => (
          <span>
            {text.gps
              ? (text.lock ? "锁定，" : "未锁，") +
                (text.online ? "在线" : "离线")
              : ""}
            <br />
            <span>{text.speed || text.time}</span>
          </span>
        ),
      },
      {
        title: "是否需要维修",
        dataIndex: "needRepair",
        render: (text) => (text === 1 ? "是" : "否"),
      },
      {
        title: "添加时间",
        dataIndex: "addTime",
        width: 135,
        render: (text) =>
          text && moment.unix(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "投放日期",
        width: 135,
        dataIndex: "pushTime",
        render: (text) =>
          text && moment.unix(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "物流单编号",
        align: "right",
        width: 160,
        dataIndex: "expNo",
      },
      // {
      //   title: "是否入库",
      //   dataIndex: "expStatus",
      //   width: 100,
      //   render: (text) => (text === 1 ? "已入库" : "未入库"),
      // },
      // {
      //   hide: localStorage.getItem("type") !== "0",
      //   title: "使用时长",
      //   width: 100,
      //   dataIndex: "useTime",
      // },
      // {
      //   hide: localStorage.getItem("type") !== "0",
      //   title: "累计金额",
      //   width: 100,
      //   dataIndex: "useMoney",
      //   render: (text) => text + "元",
      // },
      {
        title: "备注信息",
        dataIndex: "remark",
      },
      {
        title: "管理状态",
        width: 90,
        dataIndex: "",
        render: (text) =>
          text.status === 2 ? (
            "待确认"
          ) : text.status === 3 ? (
            "已销售"
          ) : text.status === 4 ? (
            "维修中"
          ) : (
            <Switch
              defaultChecked={!!text.status}
              disabled={!getJurisdiction(765) || !text.status}
              title={getJurisdiction(765) ? "" : "暂无操作权限！"}
              checkedChildren="启用"
              unCheckedChildren="禁用"
              onChange={this.handleStateChange.bind(this, text.id)}
            />
          ),
      },
      {
        title: "操作",
        dataIndex: "",
        fixed: "right",
        width: 120,
        key: "Action",
        render: (text) => (
          <span>
            <Button
              type="link"
              onClick={this.modalViewQrcode.bind(this, text.id, text.imei)}
            >
              二维码
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={!getJurisdiction(756)}
              title={getJurisdiction(756) ? "" : "暂无操作权限！"}
              onClick={this.handleEdit.bind(this, {...text})}
            >
              编辑
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              onClick={this.handleDetail.bind(this, {...text})}
            >
              查看
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={!getJurisdiction(758)}
              title={getJurisdiction(758) ? "" : "暂无操作权限！"}
              onClick={this.handleDeleteChange.bind(this, text.id)}
            >
              删除
            </Button>
            {text.status !== 0 && text.status !== 3 && (
              <>
                <Divider type="vertical" />
                <Button
                  type="link"
                  disabled={!getJurisdiction(772)}
                  title={getJurisdiction(772) ? "" : "暂无操作权限！"}
                  onClick={this.modalViewhandle.bind(this, text)}
                >
                  控制
                </Button>
                <Divider type="vertical" />
                <Button
                  type="link"
                  onClick={this.modalViewMap.bind(this, {...text})}
                >
                  定位
                </Button>
                {(text.gpsType === 2 || text.gpsType === 4 || text.gpsType === 5) && (
                  <>
                    <Divider type="vertical" />
                    <Button
                      type="link"
                      onClick={this.handleStatistic.bind(this, text)}
                    >
                      数据
                    </Button>
                  </>
                )}
              </>
            )}

            {text.ftype != 1 ? null : text.kid ? (
              <>
                <Divider type="vertical" />
                <Button
                  type="link"
                  disabled={!getJurisdiction(1011)}
                  title={getJurisdiction(1011) ? "" : "暂无操作权限！"}
                  onClick={this.handleBindOrBackUser.bind(
                    this,
                    text.id,
                    text.kid
                  )}
                >
                  退还
                </Button>
              </>
            ) : (
              <>
                <Divider type="vertical" />
                <Button
                  type="link"
                  disabled={!getJurisdiction(1010)}
                  title={getJurisdiction(1010) ? "" : "暂无操作权限！"}
                  onClick={this.handleBindOrBackUser.bind(
                    this,
                    text.id,
                    text.kid
                  )}
                >
                  绑定用户
                </Button>
              </>
            )}
          </span>
        ),
      },
    ];
    this.columns = this.columns.filter(item=>!item.hide)
    this.deviceChangeColumns = [
      {
        title: "序号",
        width: 100,
        dataIndex: "id",
      },
      {
        title: "订单编号",
        align: "right",
        width: 180,
        dataIndex: "orderSn",
      },
      {
        title: "订单状态",
        dataIndex: "orderStatusName",
      },
      {
        title: "绑定时间",
        width: 135,
        dataIndex: "addtime",
      },
      {
        title: "解绑时间",
        width: 135,
        dataIndex: "unbindTime",
      },
      {
        title: "套餐金额",
        dataIndex: "pmoney",
        render: (text) => text && text + "元",
      },
      {
        hide: localStorage.getItem("type") !== "0",
        title: "使用时长",
        dataIndex: "useTime",
      },
      {
        hide: localStorage.getItem("type") !== "0",
        title: "累计收益",
        dataIndex: "useMoney",
        render: (text) => text && text + "元",
      },
      {
        title: "操作",
        dataIndex: "",
        width: 100,
        fixed: "right",
        key: "Action",
        render: (text) => (
          <Link
            target="_blank"
            to={"/packageOrderManagement?orderSn=" + text.orderSn}
          >
            查看详情
          </Link>
        ),
      },
    ];
    this.deviceChangeColumns = this.deviceChangeColumns.filter(item=>!item.hide)
    this.logColumns = [
      {
        title: "序号",
        width: 100,
        dataIndex: "id",
      },
      {
        title: "订单编号",
        align: "right",
        width: 200,
        dataIndex: "orderSn",
      },
      {
        title: "订单状态",
        dataIndex: "orderStatusName",
      },
      {
        title: "订单日期",
        dataIndex: "createTime",
        render: (text) =>
          text && moment.unix(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "操作",
        dataIndex: "",
        width: 100,
        fixed: "right",
        key: "Action",
        render: (text) => (
          <Link
            target="_blank"
            to={"/packageOrderManagement?orderSn=" + text.porderSn}
          >
            查看详情
          </Link>
        ),
      },
    ];
    this.repairLogColumns = [
      {
        title: "序号",
        width: 100,
        dataIndex: "id",
      },
      {
        title: "代理商",
        dataIndex: "fname",
      },
      {
        title: "维修点",
        dataIndex: "rname",
      },
      {
        title: "检修名称",
        dataIndex: "detailStr",
      },
      {
        title: "检修费用",
        align: "right",
        width: 100,
        dataIndex: "amount",
        render: (text) => text && text.toFixed(2),
      },
      {
        title: "车辆信息",
        align: "right",
        dataIndex: "imei",
      },
      {
        title: "订单号",
        align: "right",
        dataIndex: "orderSn",
      },
      {
        title: "骑手信息",
        width: 130,
        dataIndex: "",
        render: (text) => text.realname + text.mobile,
      },
      {
        title: "备注",
        dataIndex: "remark",
      },
      {
        title: "下单时间",
        width: 120,
        dataIndex: "addTime",
        render: (text) => text && moment(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "支付状态",
        dataIndex: "payState",
        render: (text) => (text === 0 ? "未支付" : text === 1 ? "已支付" : ""),
      },
      {
        title: "支付时间",
        width: 120,
        dataIndex: "payTime",
        render: (text) => text && moment(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "流水号",
        align: "right",
        dataIndex: "tradeNo",
      },
      {
        title: "操作",
        dataIndex: "",
        width: 100,
        fixed: "right",
        key: "Action",
        render: (text) => (
          <Link target="_blank" to={"/bikeRepairDetailList?imei=" + text.imei}>
            查看详情
          </Link>
        ),
      },
    ];
    this.useLogColumns = [
      {
        title: "序号",
        width: 100,
        dataIndex: "no",
        render: (text, record, index) => index + 1,
      },
      {
        title: "使用单号",
        dataIndex: "useNo",
      },
      {
        title: "使用人",
        dataIndex: "userName",
      },
      {
        title: "联系方式",
        dataIndex: "mobile",
      },
      {
        title: "绑定站点",
        dataIndex: "sname",
      },
      {
        title: "退还站点",
        dataIndex: "tsName",
      },
      {
        title: "开始时间",
        width: 120,
        dataIndex: "startTime",
      },
      {
        title: "结束时间",
        width: 120,
        dataIndex: "endTime",
      },
      {
        title: "绑定照片",
        dataIndex: "imgList",
        render: (text) => {
          return (
            <Button type="link" onClick={this.handlePreview.bind(this, text)}>
              查看图片
            </Button>
          );
        },
      },
    ];
    this.transferLogColumns = [
      {
        title: "序号",
        width: 100,
        dataIndex: "no",
        render: (text, record, index) => index + 1,
      },
      {
        title: "调出站",
        dataIndex: "transferInFrom",
      },
      {
        title: "调入站",
        dataIndex: "transferOutSite",
      },
      {
        title: "操作账号",
        dataIndex: "userName",
      },
      {
        title: "调拨时间",
        dataIndex: "transferTime",
      },
    ];
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          {localStorage.getItem("type") === "0" && (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="代理商">
                {getFieldDecorator("fidSearch", {
                  initialValue: this.state.queryFormValues.fid,
                })(
                  <Select
                    placeholder="请选择"
                    onChange={this.getSiteList.bind(this)}
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    <Option value="">全部</Option>
                    {this.state.franchiseeList.length &&
                      this.state.franchiseeList.map((item, index) => {
                        return (
                          <Option key={index} value={item.fid}>
                            {item.fname}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          {localStorage.getItem("userId") === "1288" ? null : (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="站点">
                {getFieldDecorator("sidSearch")(
                  <Select
                    placeholder="请选择"
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    <Option value="">全部</Option>
                    {this.state.siteList.length &&
                      this.state.siteList.map((item, index) => {
                        return (
                          <Option key={index} value={item.sid}>
                            {item.sname}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="车辆信息">
              {getFieldDecorator("imeiSearch")(
                <Input 
                  addonBefore={
                    getFieldDecorator("numberType", {
                      initialValue: 0,
                    })(
                      <Select style={{ width: 80 }}>
                        <Option value={0}>全部</Option>
                        <Option value={1}>二维码</Option>
                        <Option value={2}>gps</Option>
                        <Option value={3}>编号</Option>
                      </Select>
                    )
                  }
                  allowClear 
                  placeholder="请输入" 
                />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="添加时间">
              {getFieldDecorator("addTime")(
                <RangePicker format="YYYY-MM-DD" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="投放时间">
              {getFieldDecorator("pushTime")(
                <RangePicker format="YYYY-MM-DD" />
              )}
            </Form.Item>
          </Col>
          {localStorage.getItem("userId") === "1288" ? null : (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="投放状态">
                {getFieldDecorator("pushStatus")(
                  <Select placeholder="请选择" allowClear>
                    <Option value="">全部</Option>
                    <Option value="0">未投放</Option>
                    <Option value="1">已投放</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          {localStorage.getItem("userId") === "1288" ? null : (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="管理状态">
                {getFieldDecorator("statusSearch", {
                  initialValue: this.state.queryFormValues.status,
                })(
                  <Select placeholder="请选择" allowClear>
                    <Option value="">全部</Option>
                    <Option value="0">禁用</Option>
                    <Option value="1">启用</Option>
                    <Option value="2">待确认</Option>
                    <Option value="3">已销售</Option>
                    <Option value="4">维修中</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="车辆型号">
              {getFieldDecorator("modelIdSearch")(
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  <Option value="">全部</Option>
                  {this.state.bikeModelList.length &&
                    this.state.bikeModelList.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="是否需要维修">
              {getFieldDecorator("needRepairSearch")(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value="0">否</Option>
                  <Option value="1">是</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          {localStorage.getItem("userId") === "1288" ? null : (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="使用订单">
                {getFieldDecorator("useOrder", {
                  initialValue: this.state.queryFormValues.useOrder,
                })(
                  <Select placeholder="请选择" allowClear>
                    <Option value="">所有</Option>
                    <Option value="Y">已使用</Option>
                    <Option value="N">未使用</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          {localStorage.getItem("userId") === "1288" ? null : (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="使用时间">
                {getFieldDecorator("useDay")(
                  <Select placeholder="请选择" allowClear>
                    <Option value={0}>未使用</Option>
                    <Option value={1}>30天以内</Option>
                    <Option value={2}>30到60天之间</Option>
                    <Option value={3}>60到90天之间</Option>
                    <Option value={4}>90天以上</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                disabled={!getJurisdiction(757)}
                title={getJurisdiction(757) ? "" : "暂无操作权限！"}
                onClick={this.handleExportChange.bind(this)}
                style={{ marginLeft: 10 }}
                loading={this.state.exportload}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    const {
      loading,
      selectedRowKeys,
      bikeList,
      initialValues,
      pageSize,
      totalCount,
      bikeOrderList,
      deviceChangeList,
      bikeRepairLogList,
      useLogList,
      transferLogList,
      curPage,
      curId,
      userVisible,
      backVisible,
      queryFormValues,
      previewVisible,
      imgList,
      statisticVisible,
      curImei,
    } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: (record) => {
        let disabled = false;
        if (record.ftype) {
          disabled = !!record.kid;
        } else {
          disabled = !(
            !record.orderSn &&
            !!record.sname &&
            record.rtype != 2 &&
            record.status != 2 &&
            record.status != 4
          );
        }
        return {
          disabled,
          // orderSn: record.orderSn,
          // sname: record.sname,
        };
      },
    };
    const hasSelected = selectedRowKeys.length > 0;
    return (
      <div>
        {!!getJurisdiction(737) ? (
          <div className="BikeManagement">
            <div className="select-form">{this.renderForm()}</div>
            <div className="top-btn">
              <Button
                icon="plus"
                type="primary"
                disabled={!getJurisdiction(754)}
                title={getJurisdiction(754) ? "" : "暂无操作权限！"}
                onClick={this.handleCreate.bind(this)}
              >
                添加
              </Button>
              <Button
                icon="cloud-upload"
                disabled={!getJurisdiction(738)}
                title={getJurisdiction(738) ? "" : "暂无操作权限！"}
                onClick={this.showImportModal.bind(this)}
              >
                导入数据
              </Button>
              <Button
                onClick={this.showPushModal.bind(this)}
                disabled={!getJurisdiction(952) || !hasSelected}
                loading={loading}
                icon="plus"
                type="danger"
              >
                调拨
              </Button>
              {hasSelected && `已选中 ${selectedRowKeys.length} 项`}
            </div>
            <div className="content-table">
              <Table
                rowKey="id"
                bordered
                rowSelection={rowSelection}
                columns={this.columns}
                dataSource={bikeList}
                scroll={{ x: 2500, y: 500 }}
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
            <BindUser
              visible={userVisible}
              id={curId}
              flag={1}
              close={() => this.closeUserModal()}
              done={() => this.getBikeList(curPage, pageSize, queryFormValues)}
            />
            <BackUser
              visible={backVisible}
              id={curId}
              flag={1}
              close={() => this.closeSendBackModal()}
              done={() => this.getBikeList(curPage, pageSize, queryFormValues)}
            />
            <Modal
              visible={previewVisible}
              footer={null}
              onCancel={() => this.setState({ previewVisible: false })}
            >
              {imgList.map((item) => (
                <img key={item} style={{ width: "100%" }} src={item} />
              ))}
            </Modal>
            <EditBike
              wrappedComponentRef={(ref) => (this.editRef = ref)}
              done={() =>{
                this.getBikeList(curPage, pageSize, queryFormValues)
              }}
            />
            <TransferModal
              wrappedComponentRef={(ref) => (this.transferRef = ref)}
              type="1"
              done={() => {
                this.getBikeList(curPage, pageSize, queryFormValues)
                this.initPushFormModal()
              }}
            />
            <ImportBike
              wrappedComponentRef={(ref) => (this.importRef = ref)}
              done={() =>{
                this.getBikeList(curPage, pageSize, queryFormValues)
              }}
            />
            <Modal
              className="bigModal"
              title="车辆详情"
              destroyOnClose
              maskClosable={false}
              visible={this.state.visibleDetail}
              onOk={this.handleDetailOk.bind(this)}
              onCancel={this.initDetailModal.bind(this)}
            >
              <Tabs defaultActiveKey="1">
                <TabPane tab="基本信息" key="1">
                  <Row>
                    <Col span={12}>
                      <p>
                        IMEI：
                        <span style={{ color: "#999" }}>
                          {initialValues.imei}
                        </span>
                      </p>
                    </Col>
                    <Col span={12}>
                      <p>
                        管理状态：
                        <span style={{ color: "#999" }}>
                          {initialValues.status === 1
                            ? "启用"
                            : initialValues.status === 0
                            ? "禁用"
                            : initialValues.status === 2
                            ? "待确认"
                            : initialValues.status === 3
                            ? "已销售"
                            : ""}
                        </span>
                      </p>
                    </Col>
                    <Col span={12}>
                      <p>
                        设备状态：
                        <span style={{ color: "#999" }}>
                          {initialValues.ftype == 1
                            ? initialValues.useStatus
                            : initialValues.orderId
                            ? "在租"
                            : "空闲"}
                        </span>
                      </p>
                    </Col>
                    <Col span={12}>
                      <p>
                        投放状态：
                        <span style={{ color: "#999" }}>
                          {initialValues.pushTime ? "已投放" : "未投放"}
                        </span>
                      </p>
                    </Col>
                    <Col span={12}>
                      <p>
                        入库时间：
                        <span style={{ color: "#999" }}>
                          {initialValues.ctTime
                            ? moment
                                .unix(initialValues.ctTime)
                                .format("YYYY-MM-DD HH:mm:ss")
                            : "暂无"}
                        </span>
                      </p>
                    </Col>
                    <Col span={12}>
                      <p>
                        投放时间：
                        <span style={{ color: "#999" }}>
                          {initialValues.pushTime
                            ? moment
                                .unix(initialValues.pushTime)
                                .format("YYYY-MM-DD HH:mm:ss")
                            : "暂无"}
                        </span>
                      </p>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        clear: "both",
                        fontSize: "16px",
                        fontWeight: "500",
                        borderBottom: "1px solid #e8e8e8",
                        margin: "25px 0",
                        paddingBottom: "10px",
                      }}
                    >
                      投放所属
                    </Col>
                    <Col span={24}>
                      <p>
                        代理商：
                        <span style={{ color: "#999" }}>
                          {initialValues.fname}
                        </span>
                      </p>
                    </Col>
                    <Col span={24}>
                      <p>
                        站点：
                        <span style={{ color: "#999" }}>
                          {initialValues.sname}
                        </span>
                      </p>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="历史订单" key="2">
                  <Table
                    rowKey="id"
                    bordered
                    columns={this.logColumns}
                    dataSource={bikeOrderList}
                    scroll={{ x: 700, y: 500 }}
                  />
                </TabPane>
                <TabPane tab="绑定记录" key="3">
                  <Table
                    rowKey="id"
                    bordered
                    columns={this.deviceChangeColumns}
                    dataSource={deviceChangeList}
                    scroll={{ x: 1200, y: 500 }}
                  />
                </TabPane>
                <TabPane tab="维修记录" key="4">
                  <Table
                    rowKey="id"
                    bordered
                    columns={this.repairLogColumns}
                    dataSource={bikeRepairLogList}
                    scroll={{ x: 1800, y: 500 }}
                  />
                </TabPane>
                {!getJurisdiction(1001) ? null : (
                  <TabPane tab="使用记录" key="5">
                    <Table
                      rowKey="id"
                      bordered
                      columns={this.useLogColumns}
                      dataSource={useLogList}
                      scroll={{ x: 1800, y: 500 }}
                    />
                  </TabPane>
                )}
                {!getJurisdiction(1006) ? null : (
                  <TabPane tab="调拨记录" key="6">
                    <Table
                      rowKey="id"
                      bordered
                      columns={this.transferLogColumns}
                      dataSource={transferLogList}
                      scroll={{ x: 1800, y: 500 }}
                    />
                  </TabPane>
                )}
              </Tabs>
            </Modal>
            <Modal
              title="车辆数据"
              footer={null}
              width={1500}
              visible={statisticVisible}
              onOk={() => this.setState({ statisticVisible: false })}
              onCancel={() => this.setState({ statisticVisible: false })}
            >
              {!statisticVisible ? null : <BikeStatistic imei={curImei} />}
            </Modal>
          </div>
        ) : (
          "暂无操作权限！"
        )}
      </div>
    );
  }
  componentDidMount() {
    if (this.state.userType != 0) {
      this.getSiteList(localStorage.getItem("fid"));
    }
    this.getFranchiseeList();
    this.getBikeModelList();
    this.getBikeList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
  }
  handlePreview(imgList) {
    this.setState({
      previewVisible: true,
      imgList,
    });
  }
  //查看详情（打开）
  showDetialModal() {
    this.setState({
      visibleDetail: true,
    });
  }

  //查看详情（确定）
  handleDetailOk(e) {
    this.setState({
      id: "",
      initialValues: {},
      visibleDetail: false,
    });
  }

  //查看详情（关闭）
  initDetailModal(e) {
    this.setState({
      id: "",
      initialValues: {},
      visibleDetail: false,
    });
  }
  // 编辑
  handleEdit({id,orderId}){
    this.editRef.open(2,{
      id,
      orderId,
    })
  }
  // 创建
  handleCreate(){
    this.editRef.open(1,{})
  }
  //获取车辆详情
  async handleDetail({id,orderId}){
    const params = { id };
    const res = await _bikeDetail(params)
    const {code,data={}} = res.data || {}
    if(code != 200){
      return
    }
    this.setState({
      id,
      initialValues: data || {},
      orderId,
    });
    this.showDetialModal();
    this.getBikeOrderListList(id);
    this.getDeviceChangeList(id);
    this.getBikeRepairLogList(id);
    this.getUseLogList(id);
    this.getTransferLogList(data.imei);
  }
  //获取车量订单列表
  getBikeOrderListList(bikeId) {
    let params = {
      bikeId,
      curPage: 1,
      pageSize: 999,
    };
    _bikeOrderList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            bikeOrderList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取设备绑定记录
  getDeviceChangeList(deviceId) {
    let params = {
      deviceId,
      flag: 1,
      curPage: 1,
      pageSize: 999,
    };
    _deviceChangeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            deviceChangeList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //车辆维修记录
  getBikeRepairLogList(bikeId) {
    let params = {
      bikeId,
      curPage: 1,
      pageSize: 999,
    };
    _bikeRepairLogList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            bikeRepairLogList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //使用记录
  getUseLogList(bikeId) {
    let params = {
      bikeId,
      flag: 1,
      curPage: 1,
      pageSize: 999,
    };
    _deviceUseLog(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            useLogList: res.data.data || [],
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //调拨记录
  getTransferLogList(imei) {
    let params = {
      imei,
      curPage: 1,
      pageSize: 999,
    };
    _kaBikeTransferList(params)
      .then((res) => {
        if (res.data.code === 200) {
          let { rows = [] } = res.data.data || {};
          this.setState({
            transferLogList: rows || [],
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //获取车辆型号列表
  getBikeModelList() {
    let params;
    if (this.state.userType === "0") {
      params = {
        curPage: 1,
        pageSize: 999,
      };
      _bikeModelList(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              bikeModelList: res.data.data.rows,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      params = {
        fid: localStorage.getItem("fid"),
        curPage: 1,
        pageSize: 999,
      };
      _merchantBikeModels(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              bikeModelList: res.data.data,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  // 导出
  handleExportChange = (e) => {
    e.preventDefault();
    this.setState({
      exportload: true,
    });
    const { form } = this.props;
    form.validateFields((err, values) => {
      // if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      delete values.status;
      if (values.addTime && values.addTime.length) {
        values.addTimeStart = moment(values.addTime[0]).format("YYYY-MM-DD");
        values.addTimeEnd = moment(values.addTime[1]).format("YYYY-MM-DD");
      }
      delete values.addTime;
      if (values.pushTime && values.pushTime.length) {
        values.pushTimeStart = moment(values.pushTime[0]).format("YYYY-MM-DD");
        values.pushTimeEnd = moment(values.pushTime[1]).format("YYYY-MM-DD");
      }
      delete values.pushTime;
      switch (values.useDay) {
        case 0:
          values.minDay = 0;
          values.maxDay = 1;
          delete values.useDay;
          break;
        case 1:
          values.minDay = 1;
          values.maxDay = 30;
          delete values.useDay;
          break;
        case 2:
          values.minDay = 30;
          values.maxDay = 60;
          delete values.useDay;
          break;
        case 3:
          values.minDay = 60;
          values.maxDay = 90;
          delete values.useDay;
          break;
        case 4:
          values.minDay = 90;
          delete values.useDay;
          break;
        default:
          delete values.useDay;
          break;
      }
      if (values.statusSearch) {
        values.status = values.statusSearch;
        delete values.statusSearch;
      }
      if (values.modelIdSearch) {
        values.modelId = values.modelIdSearch;
        delete values.modelIdSearch;
      }
      if (values.fidSearch) {
        values.fid = values.fidSearch;
        delete values.fidSearch;
      }
      if (values.sidSearch) {
        values.sid = values.sidSearch;
        delete values.sidSearch;
      }
      if (values.imeiSearch) {
        values.imei = values.imeiSearch;
        delete values.imeiSearch;
      }
      if (values.needRepairSearch) {
        values.needRepair = values.needRepairSearch;
        delete values.needRepairSearch;
      }
      let params = {
        ...values,
      };
      _exportBikeList(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              exportload: false,
            });
            window.open(`${res.data.data}`, "_blank").focus();
          } else {
            message.warning(res.data.message);
            this.setState({
              exportload: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  //获取代理商列表
  getFranchiseeList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _franchiseeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            franchiseeList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取站点列表
  getSiteList(value) {
    let params = {
      fid: value,
      curPage: 1,
      pageSize: 999,
    };
    _siteBoxList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            siteList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //二维码查看
  modalViewQrcode(deviceId, imei) {
    let params = { deviceId, type: "bike" };
    _getQRCode(params)
      .then((res) => {
        if (res.data.code === 200) {
          Modal.info({
            title: `车辆二维码 [${imei}]`,
            content: (
              <img src={res.data.data} style={{ width: "100%" }} alt="" />
            ),
            onOk() {},
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //车辆操作
  modalViewhandle(text) {
    if (text.gps) {
      if (text.online) {
        let instructType;
        Modal.confirm({
          title: `车辆操作 [${text.imei}]`,
          content: (
            <Radio.Group
              onChange={(e) => {
                instructType = e.target.value;
              }}
            >
              <Radio disabled={text.lock} value={1}>
                锁车
              </Radio>
              <Radio disabled={!text.lock} value={2}>
                开锁
              </Radio>
              {text.gpsType !== 3 && <Radio value={3}>寻车</Radio>}
            </Radio.Group>
          ),
          onOk() {
            let params = {
              id: text.id,
              instructType,
            };
            _sendInstructionBike(params)
              .then((res) => {
                if (res.data.code === 200) {
                  message.success(res.data.message);
                } else {
                  message.warning(res.data.message);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          },
        });
      } else {
        message.warning("车辆离线无法进行操作！");
      }
    } else {
      message.warning("车辆未安装定位系统无法进行操作！");
    }
  }

  //经纬度转地址
  async getAddressByPosition(lnglat) {
    return new Promise(async (resolve, reject) => {
      let AMap = await AMapLoader.load({
        key: amapkey,
        version: "2.0",
      });
      AMap.plugin("AMap.Geocoder", function () {
        let geocoder = new AMap.Geocoder({
          city: "010",
        });
        geocoder.getAddress(lnglat, function (status, result) {
          if (status === "complete" && result.info === "OK") {
            resolve(result.regeocode);
          } else {
            resolve({});
          }
        });
      });
    });
  }
  //查看定位
  modalViewMap({id,imei}) {
    const _that = this;
    let params = { id };
    _devicePositionBike(params)
      .then(async (res) => {
        if (!!res.data.data) {
          let mapPosition = [
            parseFloat(res.data.data.lon),
            parseFloat(res.data.data.lat),
          ];
          const regeocode = await this.getAddressByPosition(mapPosition);
          let modal_instance = Modal.info({
            title: `查看定位 [${imei}]`,
            width: "1000px",
            maskClosable: true,
            content: (
              <div style={{ height: 500 }}>
                <Map amapkey={amapkey} zoom={14} center={mapPosition}>
                  <Marker
                    position={mapPosition}
                    events={{
                      click: () => {
                        modal_instance.destroy();
                        _that.handleStatistic({ imei });
                      },
                    }}
                  />
                  <InfoWindow
                    visible={true}
                    position={mapPosition}
                    offset={[0, -40]}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingBottom: 10,
                        width: 300,
                      }}
                    ></div>
                    <p>最后定位时间：{res.data.data.time}</p>
                    <p>最后定位地址：{res.data.data.address}</p>
                    {/* <p>最后定位地址：{regeocode.formattedAddress}</p> */}
                  </InfoWindow>
                </Map>
              </div>
            ),
            onOk() {},
          });
        } else {
          message.warning("未查询到该车辆的定位信息！");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleStatistic({ imei, gpsType}) {
    this.setState({
      statisticVisible: true,
      curImei: imei,
    });
  }

  //车辆状态Switch
  handleStateChange(id, checked) {
    let params = { id, status: Number(checked) };
    _updateBikeStatus(params)
      .then((res) => {
        if (res.data.code === 200) {
          message.success("操作成功");
          this.getBikeList(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues
          );
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //单条删除
  handleDeleteChange(id) {
    let self = this;
    Modal.confirm({
      title: "删除信息",
      content: "是否确定删除此数据？",
      onOk() {
        let params = { id };
        _deleteBike(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              self.getBikeList(
                self.state.curPage,
                self.state.pageSize,
                self.state.queryFormValues
              );
            }
          })
          .catch((err) => {
            console.log(err);
            message.warning("操作失败");
          });
      },
    });
  }
  // 开启绑定用户弹框
  handleBindOrBackUser(id, kid) {
    if (kid) {
      this.setState({
        backVisible: true,
        curId: id,
      });
    } else {
      this.setState({
        userVisible: true,
        curId: id,
      });
    }
  }
  // 关闭退还弹框
  closeSendBackModal() {
    this.setState({
      backVisible: false,
    });
  }
  // 关闭绑定用户弹框
  closeUserModal() {
    this.setState({
      userVisible: false,
    });
  }
  //获取车辆列表
  getBikeList(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage,
      pageSize,
    };
    _bikeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          let bikeList = res.data.data.rows;
          this.setState({
            bikeList,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
          for(let i = 0; i < bikeList.length; i++){
            const {id} = bikeList[i]
            this.getListStatus(id)
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  async getListStatus(id){
    if(!id) return;
    const res = await _getBikeStatus({ids: [id]})
    const {code,data=[]} = res.data || {}
    if (code != 200) return;
    const [row={}] = data || []
    const {bikeList} = this.state
    let bool = false
    const newList = bikeList.map((item) => {
      if (item.id === row.id) {
        item.gpsType = row.gpsType;
        item.gps = row.gps;
        item.lock = row.lock;
        item.online = row.online;
        item.speed = row.speed;
        item.time = row.time;
        bool = true
      }
      return item
    });
    if(bool){
      this.setState({
        bikeList: newList
      })
    }
  }
  //分页
  handlePageChange(curPage, pageSize) {
    this.setState({
      selectedRowKeys: [],
    });
    this.getBikeList(curPage, pageSize, this.state.queryFormValues);
  }

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      // if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      delete values.status;
      if (values.addTime && values.addTime.length) {
        values.addTimeStart = moment(values.addTime[0]).format("YYYY-MM-DD");
        values.addTimeEnd = moment(values.addTime[1]).format("YYYY-MM-DD");
      }
      delete values.addTime;
      if (values.pushTime && values.pushTime.length) {
        values.pushTimeStart = moment(values.pushTime[0]).format("YYYY-MM-DD");
        values.pushTimeEnd = moment(values.pushTime[1]).format("YYYY-MM-DD");
      }
      delete values.pushTime;
      switch (values.useDay) {
        case 0:
          values.minDay = 0;
          values.maxDay = 1;
          delete values.useDay;
          break;
        case 1:
          values.minDay = 1;
          values.maxDay = 30;
          delete values.useDay;
          break;
        case 2:
          values.minDay = 30;
          values.maxDay = 60;
          delete values.useDay;
          break;
        case 3:
          values.minDay = 60;
          values.maxDay = 90;
          delete values.useDay;
          break;
        case 4:
          values.minDay = 90;
          delete values.useDay;
          break;
        default:
          delete values.useDay;
          break;
      }
      if (values.statusSearch) {
        values.status = values.statusSearch;
        delete values.statusSearch;
      }
      if (values.modelIdSearch) {
        values.modelId = values.modelIdSearch;
        delete values.modelIdSearch;
      }
      if (values.fidSearch) {
        values.fid = values.fidSearch;
        delete values.fidSearch;
      }
      if (values.sidSearch) {
        values.sid = values.sidSearch;
        delete values.sidSearch;
      }
      if (values.imeiSearch) {
        values.imei = values.imeiSearch;
        delete values.imeiSearch;
      }
      if (values.needRepairSearch) {
        values.needRepair = values.needRepairSearch;
        delete values.needRepairSearch;
      }
      this.setState({
        queryFormValues: values,
      });
      this.getBikeList(1, this.state.pageSize, values);
    });
  };
  //打开调拨
  showPushModal() {
    this.props.form && this.props.form.resetFields();
    const { selectedRowKeys, bikeList } = this.state;
    let selectedData = bikeList.filter((item) =>
      selectedRowKeys.includes(item.id)
    );
    this.setState({ selectedData });
    this.transferRef.open({ selectedData });
  }

  //已选中数据
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  //Modal取消事件（投放）
  initPushFormModal() {
    this.props.form && this.props.form.resetFields();
    this.setState({
      selectedRowKeys: [],
      selectedData: [],
    });
  }

  //Modal打开事件（导出）
  showImportModal() {
    this.importRef.open();
  }
}
BikeManagement = Form.create({})(BikeManagement);
export { BikeManagement };
