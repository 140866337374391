/* eslint-disable eqeqeq */
import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { Link } from "react-router-dom";
import { Tooltip, Icon, Button } from "antd";
import FormSearch from "./components/FormSearch.jsx";
import DataLabelingModal from "./components/DataLabelingModal.jsx";
import CommonTable from "@/components/commonTable";
import moment from "moment";
import { _urgentDetailList } from "@/statics/js/api";
import queryString from "query-string";

const columns = [
  {
    title: "订单号",
    width: 180,
    dataIndex: "orderSn",
    render: (text) => (
      <Link target="_blank" to={"/packageOrderManagement?orderSn=" + text}>
        {text}
      </Link>
    ),
  },
  {
    title: "代理商",
    dataIndex: "fname",
    width: 150,
  },
  {
    title: "站点",
    width: 150,
    dataIndex: "sname",
  },
  {
    title: "催收方式",
    dataIndex: "urgentTypeName",
    width: 90,
  },
  {
    title: "催收时间",
    dataIndex: "urgentTimeStr",
    width: 150,
  },
  {
    title: "是否接通",
    dataIndex: "callResultStr",
    width: 90,
  },
  {
    title: "通话时长",
    dataIndex: "callTimeStr",
    width: 90,
  },
  {
    title: () => (
      <>
        <span>费用</span>&nbsp;
        <Tooltip placement="top" title="电话催收费用">
          <Icon type="question-circle" />
        </Tooltip>
      </>
    ),
    dataIndex: "callCost",
    width: 110,
    render: (text) => text?.toFixed(2),
  },
  {
    title: "用户姓名",
    dataIndex: "userName",
    width: 90,
  },
  {
    title: "联系方式",
    dataIndex: "mobile",
    width: 110,
  },
  {
    title: "是否结算",
    dataIndex: "settleName",
    width: 90,
  },
];

const ElectricCollectionReport = (props) => {
  const userType = localStorage.getItem("type");
  const tableRef = useRef();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalParams, setModalParams] = useState({});
  const [total, setTotal] = useState(0);

  useEffect(() => {
    // 默认筛选当月数据
    const timeStart = formatDate(moment().startOf("month"));
    const timeEnd = formatDate(moment());
    // url query参数 orderSn 筛选全部移除默认日期
    const { orderSn } = queryString.parse(props.location.search);
    const params = orderSn ? { orderSn } : { timeStart, timeEnd };
    handleSearch(params);
  }, []);

  const settleBtnDisabled = useMemo(() => {
    const { callResult, settle } = modalParams;
    return callResult == 0 || settle == 1 || total <= 0;
  }, [modalParams, total]);

  const handleSearch = async (params) => {
    setModalParams(params);
    const { fid, sid } = params;
    const totalCount = await tableRef.current.handleSearch({
      ...params,
      fid: fid ? fid.key : undefined,
      sid: sid ? sid.key : undefined,
    });
    setTotal(totalCount);
  };

  const formatDate = useCallback((time) => {
    return time ? moment(time).format("YYYY-MM-DD") : undefined;
  }, []);

  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleUpdateVisible = (value) => {
    setModalVisible(value);
  };

  const handleUpdateTable = () => {
    handleSearch(modalParams);
  };

  return (
    <>
      <div>
        <div className="select-form">
          <FormSearch
            search={handleSearch}
            formatDate={formatDate}
            location={props.location}
          />
        </div>
        <div className="top-btn">
          {userType === "0" && (
            <Button
              type="primary"
              disabled={settleBtnDisabled}
              onClick={handleOpenModal}
            >
              标记列表数据为已结算
            </Button>
          )}
        </div>
        <CommonTable
          ref={tableRef}
          auto={false}
          showSort
          action={_urgentDetailList}
          options={{
            columns,
            scroll: { x: 1000 },
          }}
          params={{}}
        />
        <DataLabelingModal
          modalVisible={modalVisible}
          modalParams={modalParams}
          updateVisible={handleUpdateVisible}
          updateTable={handleUpdateTable}
        />
      </div>
    </>
  );
};

export default ElectricCollectionReport;
