import React, { Component, createRef } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Table,
  Switch,
  Divider,
  Modal,
  message,
  Input,
  Radio,
  Tabs,
  Spin,
  Select,
  Form,
  DatePicker,
  Row,
  Col,
} from "antd";
import {
  _deviceChangeList,
  _batteryOrderList,
  _exportBatteryList,
  _batteryModelList,
  _merchantBatModels,
  _franchiseeList,
  _siteBoxList,
  _getQRCode,
  _getBatteryStatus,
  _sendInstructionBattery,
  _devicePositionBattery,
  _updateBatteryStatus,
  _deleteBattery,
  _batteryList,
  _saveBattery,
  _batchPushBattery,
  _batteryDetail,
  _batchImportBattery,
  _deviceUseLog,
  _kaBatteryTransferList,
} from "@/statics/js/api";
import BindUser from "./components/bindUser/index";
import BackUser from "./components/backUser/index";
import TransferModal from "./components/transferModal/index";
import LockModal from "./components/lockModal/index";
import PushModal from "./components/pushModal/index";
import BatteryStatistic from "./batteryStatistic/index";
import ImportBattery from "./components/importBattery/index";
import EditBattery from "./components/editBattery/index";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import moment from "moment";
import { Map, Marker, InfoWindow } from "react-amap";
import AMapLoader from "@amap/amap-jsapi-loader";
import "./style/index.less";
import queryString from 'query-string';
const amapkey = "4792ad0f37a527c1535e83599762d894";
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

class BatteryManagement extends Component {
  constructor(props) {
    super(props);
    const { useOrder, imei, status, fid } =  queryString.parse(this.props.location.search)
    this.state = {
      queryFormValues: {
        useOrder: useOrder,
        imei: imei,
        status: status
          ? status
          : "1",
        fid: fid
          ? Number(fid)
          : undefined,
      },
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      batteryList: [], //电池管理列表
      franchiseeList: [], //代理商列表
      batteryModelList: [], //电池型号列表
      batteryOrderList: [], //电池订单列表
      useLogList: [], //使用记录
      transferLogList: [], //调拨记录
      initialValues: {}, //电池详情
      selectedData: [], //选中电池编号
      siteList: [], //站点列表
      id: "", //电池id
      visibleDetail: false, //Modal是否打开（详情）
      selectedRowKeys: [], // Check here to configure the default column
      userType: localStorage.getItem("type"),
      userVisible: false,
      backVisible: false,
      curId: "",
      previewVisible: false,
      imgList: [],
      statisticVisible: false,
      curImei: "",
    };
    this.transferRef = createRef();
    this.pushRef = createRef();
    this.lockRef = createRef();
    this.importRef = createRef();
    this.editRef = createRef();
    const { userType } = this.state;
    this.columns = [
      {
        title: "序号",
        dataIndex: "no",
        width: 100,
        render: (text, record, index) =>
          this.state.pageSize * (this.state.curPage - 1) + index + 1,
      },
      {
        title: "电池信息",
        dataIndex: "",
        render: (text) =>
          text && (
            <span>
              {text.batteryId && "电池编号：" + text.batteryId}
              {text.batteryId && <br />}
              {text.imei && "二维码：" + text.imei}
              {text.imei && <br />}
              {text.ccodeId && "GPS号：" + text.ccodeId}
            </span>
          ),
      },
      {
        title: "代理商",
        dataIndex: "",
        render: (text) => (
          <span>
            {text.fname}
            <br />
            {text.sname}
          </span>
        ),
      },
      // {
      //   title: "电池型号",
      //   dataIndex: "modelName",
      // },
      // {
      //   className:
      //     userType !== "0" &&
      //     userType !== "8" &&
      //     userType !== "9" &&
      //     "hideCell",
      //   title: "设备状态",
      //   dataIndex: "",
      //   render: (text) => text.bindInfo && text.bindInfo.useStatus,
      // },
      // {
      //   className:
      //     userType !== "0" &&
      //     userType !== "8" &&
      //     userType !== "9" &&
      //     "hideCell",
      //   title: "使用人",
      //   dataIndex: "",
      //   render: (text) => (
      //     <div>
      //       <div>{text.bindInfo && text.bindInfo.userName}</div>
      //       <div>{text.bindInfo && text.bindInfo.userMobile}</div>
      //     </div>
      //   ),
      // },
      // {
      //   className:
      //     userType !== "0" &&
      //     userType !== "8" &&
      //     userType !== "9" &&
      //     "hideCell",
      //   title: "使用时长",
      //   dataIndex: "",
      //   render: (text) => text.bindInfo && text.bindInfo.useDay + "（天）",
      // },
      // {
      //   className:
      //     userType !== "0" &&
      //     userType !== "8" &&
      //     userType !== "9" &&
      //     "hideCell",
      //   title: "使用单号",
      //   dataIndex: "",
      //   render: (text) => text.bindInfo && text.bindInfo.useNo,
      // },
      {
        title: "使用订单",
        align: "right",
        dataIndex: "orderSn",
        render: (text) => (
          <Link target="_blank" to={"/packageOrderManagement?orderSn=" + text}>
            {text}
          </Link>
        ),
      },
      {
        title: "电池状态",
        dataIndex: "",
        render: (text) => (
          <span>
            <span>
              电池状态：
              {text.online === 1 ? "在线" : text.online === 2 ? "休眠" : "离线"}
            </span>
            <br />
            <span>
              电池电量：{text.electricQuantity ? text.electricQuantity : "未知"}
            </span>
            <br />
            <span>
              充电状态：
              {text.chargeStatusName}
            </span>
            <br />
            <span>
              放电状态：
              {text.dischargingStatus === 0
                ? "未放电"
                : text.dischargingStatus === 1
                ? "放电"
                : "未知"}
            </span>
            <br />
          </span>
        ),
      },
      {
        title: "是否需要维修",
        dataIndex: "needRepair",
        render: (text) => (text === 1 ? "是" : "否"),
      },
      {
        title: "入库时间",
        dataIndex: "ctTime",
        width: 135,
        render: (text) =>
          text && moment.unix(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "投放日期",
        dataIndex: "pushTime",
        width: 135,
        render: (text) =>
          text && moment.unix(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "物流单编号",
        align: "right",
        dataIndex: "expNo",
      },
      // {
      //   title: "是否入库",
      //   dataIndex: "expStatus",
      //   width: 100,
      //   render: (text) => (text === 1 ? "已入库" : "未入库"),
      // },
      {
        title: "备注",
        dataIndex: "remark",
      },
      {
        title: "管理状态",
        width: 100,
        dataIndex: "",
        render: (text) =>
          text.status === 2 ? (
            "待确认"
          ) : text.status === 3 ? (
            "已销售"
          ) : (
            <Switch
              defaultChecked={!!text.status}
              disabled={!getJurisdiction(668)}
              title={getJurisdiction(668) ? "" : "暂无操作权限！"}
              checkedChildren="启用"
              unCheckedChildren="禁用"
              onChange={this.handleStateChange.bind(this, text.id)}
            />
          ),
      },
      {
        title: "操作",
        dataIndex: "",
        fixed: "right",
        width: 120,
        key: "Action",
        render: (text) => (
          <span>
            <Button
              type="link"
              onClick={this.modalViewQrcode.bind(this, text.id, text.imei)}
            >
              二维码
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={!getJurisdiction(752)}
              title={getJurisdiction(752) ? "" : "暂无操作权限！"}
              onClick={this.handleEdit.bind(this, { ...text })}
            >
              编辑
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={!getJurisdiction(665)}
              title={getJurisdiction(665) ? "" : "暂无操作权限！"}
              onClick={this.handleDetail.bind(this, { ...text })}
            >
              查看
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={!getJurisdiction(669)}
              title={getJurisdiction(669) ? "" : "暂无操作权限！"}
              onClick={this.handleDeleteChange.bind(this, text.id)}
            >
              删除
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={!getJurisdiction(664)}
              title={getJurisdiction(664) ? "" : "暂无操作权限！"}
              onClick={this.modalViewhandle.bind(this, text)}
            >
              控制
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              onClick={this.modalViewMap.bind(this, { ...text })}
            >
              定位
            </Button>
            {(text.batteryType === 6 || text.batteryType === 7) && (
              <>
                <Divider type="vertical" />
                <Button
                  type="link"
                  onClick={this.handleStatistic.bind(this, text)}
                >
                  数据
                </Button>
              </>
            )}
            {text.batteryType === 7 && (
              <>
                <Divider type="vertical" />
                <Link target="_blank" to={"/batteryBoard?id=" + text.id}>
                  大屏
                </Link>
              </>
            )}
            {text.ftype != 1 ? null : text.kid ? (
              <>
                <Divider type="vertical" />
                <Button
                  type="link"
                  disabled={!getJurisdiction(1009)}
                  title={getJurisdiction(1009) ? "" : "暂无操作权限！"}
                  onClick={this.handleBindOrBackUser.bind(
                    this,
                    text.id,
                    text.kid
                  )}
                >
                  退还
                </Button>
              </>
            ) : (
              <>
                <Divider type="vertical" />
                <Button
                  type="link"
                  disabled={!getJurisdiction(1004)}
                  title={getJurisdiction(1004) ? "" : "暂无操作权限！"}
                  onClick={this.handleBindOrBackUser.bind(
                    this,
                    text.id,
                    text.kid
                  )}
                >
                  绑定用户
                </Button>
              </>
            )}
          </span>
        ),
      },
    ];
    this.deviceChangeColumns = [
      {
        title: "序号",
        width: 100,
        dataIndex: "id",
      },
      {
        title: "订单编号",
        align: "right",
        width: 180,
        dataIndex: "orderSn",
      },
      {
        title: "订单状态",
        dataIndex: "orderStatusName",
      },
      {
        title: "绑定时间",
        width: 135,
        dataIndex: "addtime",
      },
      {
        title: "解绑时间",
        width: 135,
        dataIndex: "unbindTime",
      },
      {
        title: "操作",
        dataIndex: "",
        width: 100,
        fixed: "right",
        key: "Action",
        render: (text) => (
          <Link
            target="_blank"
            to={"/packageOrderManagement?orderSn=" + text.orderSn}
          >
            查看详情
          </Link>
        ),
      },
    ];
    this.logColumns = [
      {
        title: "序号",
        width: 100,
        dataIndex: "id",
      },
      {
        title: "订单编号",
        align: "right",
        width: 200,
        dataIndex: "porderSn",
      },
      {
        title: "订单状态",
        dataIndex: "isEffect",
        render: (text) =>
          text === 1
            ? "有效"
            : text === 0
            ? "无效"
            : text === 2
            ? "已生成续租订单"
            : "",
      },
      {
        title: "订单日期",
        dataIndex: "createTime",
        render: (text) =>
          text && moment.unix(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "操作",
        width: 100,
        dataIndex: "",
        key: "Action",
        fixed: "right",
        render: (text) => (
          <Link
            target="_blank"
            to={"/packageOrderManagement?orderSn=" + text.porderSn}
          >
            查看详情
          </Link>
        ),
      },
    ];
    this.useLogColumns = [
      {
        title: "序号",
        width: 100,
        dataIndex: "no",
        render: (text, record, index) => index + 1,
      },
      {
        title: "使用单号",
        dataIndex: "useNo",
      },
      {
        title: "使用人",
        dataIndex: "userName",
      },
      {
        title: "联系方式",
        dataIndex: "mobile",
      },
      {
        title: "绑定站点",
        dataIndex: "sname",
      },
      {
        title: "退还站点",
        dataIndex: "tsName",
      },
      {
        title: "开始时间",
        width: 120,
        dataIndex: "startTime",
      },
      {
        title: "结束时间",
        width: 120,
        dataIndex: "endTime",
      },
      {
        title: "绑定照片",
        dataIndex: "imgList",
        render: (text) => {
          return (
            <Button type="link" onClick={this.handlePreview.bind(this, text)}>
              查看图片
            </Button>
          );
        },
      },
    ];
    this.transferLogColumns = [
      {
        title: "序号",
        width: 100,
        dataIndex: "no",
        render: (text, record, index) => index + 1,
      },
      {
        title: "调出站",
        dataIndex: "transferInFrom",
      },
      {
        title: "调入站",
        dataIndex: "transferOutSite",
      },
      {
        title: "操作账号",
        dataIndex: "userName",
      },
      {
        title: "调拨时间",
        dataIndex: "transferTime",
      },
    ];
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          {localStorage.getItem("type") === "0" && (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="代理商">
                {getFieldDecorator("fidSearch", {
                  initialValue: this.state.queryFormValues.fid,
                })(
                  <Select
                    placeholder="请选择"
                    onChange={this.getSiteList.bind(this)}
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    <Option value="">全部</Option>
                    {this.state.franchiseeList.length &&
                      this.state.franchiseeList.map((item, index) => {
                        return (
                          <Option key={index} value={item.fid}>
                            {item.fname}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          {localStorage.getItem("userId") === "1288" ? null : (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="站点">
                {getFieldDecorator("sidSearch")(
                  <Select
                    placeholder="请选择"
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    <Option value="">全部</Option>
                    {this.state.siteList.length &&
                      this.state.siteList.map((item, index) => {
                        return (
                          <Option key={index} value={item.sid}>
                            {item.sname}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="电池信息">
              {getFieldDecorator("imeiSearch", {
                initialValue: this.state.queryFormValues.imei,
              })(
                <Input
                  addonBefore={getFieldDecorator("numberType", {
                    initialValue: 0,
                  })(
                    <Select style={{ width: 80 }}>
                      <Option value={0}>全部</Option>
                      <Option value={1}>二维码</Option>
                      <Option value={2}>gps</Option>
                      <Option value={3}>编号</Option>
                    </Select>
                  )}
                  allowClear
                  placeholder="请输入"
                />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="入库时间">
              {getFieldDecorator("ctTime")(<RangePicker format="YYYY-MM-DD" />)}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="投放时间">
              {getFieldDecorator("pushTime")(
                <RangePicker format="YYYY-MM-DD" />
              )}
            </Form.Item>
          </Col>
          {localStorage.getItem("userId") === "1288" ? null : (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="投放状态">
                {getFieldDecorator("pushStatus")(
                  <Select placeholder="请选择" allowClear>
                    <Option value="">全部</Option>
                    <Option value="0">未投放</Option>
                    <Option value="1">已投放</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          {localStorage.getItem("userId") === "1288" ? null : (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="管理状态">
                {getFieldDecorator("statusSearch", {
                  initialValue: this.state.queryFormValues.status,
                })(
                  <Select placeholder="请选择" allowClear>
                    <Option value="">全部</Option>
                    <Option value="0">禁用</Option>
                    <Option value="1">启用</Option>
                    <Option value="2">待确认</Option>
                    <Option value="3">已销售</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="电池型号">
              {getFieldDecorator("modelIdSearch")(
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  <Option value="">全部</Option>
                  {this.state.batteryModelList.length &&
                    this.state.batteryModelList.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          {localStorage.getItem("userId") === "1288" ? null : (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="使用订单">
                {getFieldDecorator("useOrder", {
                  initialValue: this.state.queryFormValues.useOrder,
                })(
                  <Select placeholder="请选择" allowClear>
                    <Option value="">所有</Option>
                    <Option value="Y">已使用</Option>
                    <Option value="N">未使用</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="是否需要维修">
              {getFieldDecorator("needRepairSearch")(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value="0">否</Option>
                  <Option value="1">是</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                disabled={!getJurisdiction(753)}
                title={getJurisdiction(753) ? "" : "暂无操作权限！"}
                onClick={this.handleExportChange.bind(this)}
                style={{ marginLeft: 10 }}
                loading={this.state.exportload}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    const {
      loading,
      selectedRowKeys,
      batteryList,
      initialValues,
      batteryOrderList,
      deviceChangeList,
      useLogList,
      transferLogList,
      pageSize,
      totalCount,
      curPage,
      curId,
      userVisible,
      backVisible,
      queryFormValues,
      previewVisible,
      imgList,
      statisticVisible,
      curImei,
    } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    return (
      <div>
        {!!getJurisdiction(663) ? (
          <div className="BatteryManagement">
            <div className="select-form">{this.renderForm()}</div>
            <div className="top-btn">
              <Button
                icon="plus"
                type="primary"
                disabled={!getJurisdiction(750)}
                title={getJurisdiction(750) ? "" : "暂无操作权限！"}
                onClick={this.handleCreate.bind(this)}
              >
                添加
              </Button>
              <Button
                icon="cloud-upload"
                disabled={!getJurisdiction(751)}
                title={getJurisdiction(751) ? "" : "暂无操作权限！"}
                onClick={this.showImportModal.bind(this)}
              >
                导入数据
              </Button>
              <Button
                onClick={this.showPushModal.bind(this)}
                disabled={!getJurisdiction(666) || !hasSelected}
                loading={loading}
                icon="vertical-align-bottom"
                type="primary"
                ghost
              >
                投放
              </Button>
              <Button
                onClick={this.showTransferModal.bind(this)}
                disabled={!getJurisdiction(1002) || !hasSelected}
                icon="branches"
                type="primary"
                ghost
              >
                调拨
              </Button>
              <Button
                onClick={this.showLockModal.bind(this, 1)}
                disabled={!getJurisdiction(1097) || !hasSelected}
                type="primary"
                ghost
                icon="lock"
              >
                锁电
              </Button>
              <Button
                onClick={this.showLockModal.bind(this, 2)}
                disabled={!getJurisdiction(1096) || !hasSelected}
                type="primary"
                ghost
                icon="unlock"
              >
                解锁
              </Button>
              {hasSelected && `已选中 ${selectedRowKeys.length} 项`}
            </div>
            <div className="content-table">
              <Table
                rowKey="id"
                bordered
                rowSelection={rowSelection}
                columns={this.columns}
                dataSource={batteryList}
                scroll={{ x: 2500, y: 500 }}
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
            <BindUser
              visible={userVisible}
              id={curId}
              flag={2}
              close={() => this.closeUserModal()}
              done={() =>
                this.getBatteryList(curPage, pageSize, queryFormValues)
              }
            />
            <BackUser
              visible={backVisible}
              id={curId}
              flag={2}
              close={() => this.closeSendBackModal()}
              done={() =>
                this.getBatteryList(curPage, pageSize, queryFormValues)
              }
            />
            <TransferModal
              wrappedComponentRef={(ref) => (this.transferRef = ref)}
              type="2"
              done={() => {
                this.getBatteryList(curPage, pageSize, queryFormValues);
                this.initPushFormModal();
              }}
            />
            <PushModal
              wrappedComponentRef={(ref) => (this.pushRef = ref)}
              done={() => {
                this.getBatteryList(curPage, pageSize, queryFormValues);
                this.initPushFormModal();
              }}
            />
            <LockModal
              ref={this.lockRef}
              done={() => {
                this.getBatteryList(curPage, pageSize, queryFormValues);
                this.initPushFormModal();
              }}
            />
            <Modal
              visible={previewVisible}
              footer={null}
              onCancel={() => this.setState({ previewVisible: false })}
            >
              {imgList.map((item) => (
                <img key={item} style={{ width: "100%" }} src={item} />
              ))}
            </Modal>
            <EditBattery
              wrappedComponentRef={(ref) => (this.editRef = ref)}
              done={() => {
                this.getBatteryList(curPage, pageSize, queryFormValues);
              }}
            />
            <ImportBattery
              wrappedComponentRef={(ref) => (this.importRef = ref)}
              done={() => {
                this.getBatteryList(curPage, pageSize, queryFormValues);
              }}
            />
            <Modal
              className="bigModal"
              title="电池详情"
              destroyOnClose
              maskClosable={false}
              visible={this.state.visibleDetail}
              onOk={this.handleDetailOk.bind(this)}
              onCancel={this.initDetailModal.bind(this)}
            >
              <Tabs defaultActiveKey="1">
                <TabPane tab="基本信息" key="1">
                  <Row>
                    <Col span={12}>
                      <p>
                        IMEI：
                        <span style={{ color: "#999" }}>
                          {initialValues.imei}
                        </span>
                      </p>
                    </Col>
                    <Col span={12}>
                      <p>
                        管理状态：
                        <span style={{ color: "#999" }}>
                          {initialValues.status === 1
                            ? "启用"
                            : initialValues.status === 0
                            ? "禁用"
                            : initialValues.status === 2
                            ? "待确认"
                            : initialValues.status === 3
                            ? "已销售"
                            : ""}
                        </span>
                      </p>
                    </Col>
                    <Col span={12}>
                      <p>
                        设备状态：
                        <span style={{ color: "#999" }}>
                          {initialValues.ftype == 1
                            ? initialValues.useStatus
                            : initialValues.orderId
                            ? "在租"
                            : "空闲"}
                        </span>
                      </p>
                    </Col>
                    <Col span={12}>
                      <p>
                        投放状态：
                        <span style={{ color: "#999" }}>
                          {initialValues.pushTime ? "已投放" : "未投放"}
                        </span>
                      </p>
                    </Col>
                    <Col span={12}>
                      <p>
                        入库时间：
                        <span style={{ color: "#999" }}>
                          {initialValues.ctTime
                            ? moment
                                .unix(initialValues.ctTime)
                                .format("YYYY-MM-DD HH:mm:ss")
                            : "暂无"}
                        </span>
                      </p>
                    </Col>
                    <Col span={12}>
                      <p>
                        投放时间：
                        <span style={{ color: "#999" }}>
                          {initialValues.pushTime
                            ? moment
                                .unix(initialValues.pushTime)
                                .format("YYYY-MM-DD HH:mm:ss")
                            : "暂无"}
                        </span>
                      </p>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        clear: "both",
                        fontSize: "16px",
                        fontWeight: "500",
                        borderBottom: "1px solid #e8e8e8",
                        margin: "25px 0",
                        paddingBottom: "10px",
                      }}
                    >
                      投放所属
                    </Col>
                    <Col span={24}>
                      <p>
                        代理商：
                        <span style={{ color: "#999" }}>
                          {initialValues.fname}
                        </span>
                      </p>
                    </Col>
                    <Col span={24}>
                      <p>
                        站点：
                        <span style={{ color: "#999" }}>
                          {initialValues.sname}
                        </span>
                      </p>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="历史订单" key="2">
                  <Table
                    rowKey="id"
                    bordered
                    columns={this.logColumns}
                    dataSource={batteryOrderList}
                    scroll={{ x: 700, y: 500 }}
                  />
                </TabPane>
                <TabPane tab="绑定记录" key="3">
                  <Table
                    rowKey="id"
                    bordered
                    columns={this.deviceChangeColumns}
                    dataSource={deviceChangeList}
                    scroll={{ x: 800, y: 500 }}
                  />
                </TabPane>
                {!getJurisdiction(1000) ? null : (
                  <TabPane tab="使用记录" key="5">
                    <Table
                      rowKey="id"
                      bordered
                      columns={this.useLogColumns}
                      dataSource={useLogList}
                      scroll={{ x: 1800, y: 500 }}
                    />
                  </TabPane>
                )}
                {!getJurisdiction(1003) ? null : (
                  <TabPane tab="调拨记录" key="6">
                    <Table
                      rowKey="id"
                      bordered
                      columns={this.transferLogColumns}
                      dataSource={transferLogList}
                      scroll={{ x: 1800, y: 500 }}
                    />
                  </TabPane>
                )}
              </Tabs>
            </Modal>
            <Modal
              title="电池数据"
              footer={null}
              width={1400}
              visible={statisticVisible}
              onOk={() => this.setState({ statisticVisible: false })}
              onCancel={() => this.setState({ statisticVisible: false })}
            >
              {!statisticVisible ? null : (
                <BatteryStatistic imei={curImei} id={curId} />
              )}
            </Modal>
          </div>
        ) : (
          "暂无操作权限！"
        )}
      </div>
    );
  }
  componentDidMount() {
    this.state.userType !== "0" &&
      this.getSiteList(localStorage.getItem("fid"));
    this.getFranchiseeList();
    this.getBatteryModelList();
    this.getBatteryList(this.state.curPage, this.state.pageSize, {
      ...this.state.queryFormValues,
      numberType: 0,
    });
  }
  handlePreview(imgList) {
    this.setState({
      previewVisible: true,
      imgList,
    });
  }
  //查看详情（打开）
  showDetialModal() {
    this.setState({
      visibleDetail: true,
    });
  }

  //查看详情（确定）
  handleDetailOk(e) {
    this.setState({
      id: "",
      initialValues: {},
      visibleDetail: false,
    });
  }

  //查看详情（关闭）
  initDetailModal(e) {
    this.setState({
      id: "",
      initialValues: {},
      visibleDetail: false,
    });
  }
  // 编辑
  handleEdit({ id, orderId }) {
    this.editRef.open(2, {
      id,
      orderId,
    });
  }
  // 创建
  handleCreate() {
    this.editRef.open(1, {});
  }
  //获取电池详情
  async handleDetail({ id, orderId }) {
    const params = { id };
    const res = await _batteryDetail(params);
    const { code, data = {} } = res.data || {};
    if (code != 200) {
      return;
    }
    this.setState({
      id,
      initialValues: data || {},
      orderId,
    });
    this.showDetialModal();
    this.getBatteryOrderList(id);
    this.getDeviceChangeList(id);
    this.getUseLogList(id);
    this.getTransferLogList(data.imei);
  }
  //获取设备绑定记录
  getDeviceChangeList(deviceId) {
    let params = {
      deviceId,
      flag: 2,
      curPage: 1,
      pageSize: 999,
    };
    _deviceChangeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            deviceChangeList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //使用记录
  getUseLogList(bikeId) {
    let params = {
      bikeId,
      flag: 2,
      curPage: 1,
      pageSize: 999,
    };
    _deviceUseLog(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            useLogList: res.data.data || [],
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //调拨记录
  getTransferLogList(imei) {
    let params = {
      imei,
      curPage: 1,
      pageSize: 999,
    };
    _kaBatteryTransferList(params)
      .then((res) => {
        if (res.data.code === 200) {
          let { rows = [] } = res.data.data || {};
          this.setState({
            transferLogList: rows || [],
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //获取电池历史订单列表
  getBatteryOrderList(batteryId) {
    let params = {
      batteryId,
      curPage: 1,
      pageSize: 999,
    };
    _batteryOrderList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            batteryOrderList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // 导出
  handleExportChange = (e) => {
    e.preventDefault();
    this.setState({
      exportload: true,
    });
    const { form } = this.props;
    form.validateFields((err, values) => {
      // if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      delete values.status;
      if (values.ctTime && values.ctTime.length) {
        values.ctTimeStart = moment(values.ctTime[0]).format("YYYY-MM-DD");
        values.ctTimeEnd = moment(values.ctTime[1]).format("YYYY-MM-DD");
      }
      delete values.ctTime;
      if (values.pushTime && values.pushTime.length) {
        values.pushTimeStart = moment(values.pushTime[0]).format("YYYY-MM-DD");
        values.pushTimeEnd = moment(values.pushTime[1]).format("YYYY-MM-DD");
      }
      delete values.pushTime;
      if (values.statusSearch) {
        values.status = values.statusSearch;
        delete values.statusSearch;
      }
      if (values.modelIdSearch) {
        values.modelId = values.modelIdSearch;
        delete values.modelIdSearch;
      }
      if (values.fidSearch) {
        values.fid = values.fidSearch;
        delete values.fidSearch;
      }
      if (values.sidSearch) {
        values.sid = values.sidSearch;
        delete values.sidSearch;
      }
      if (values.imeiSearch) {
        values.imei = values.imeiSearch;
        delete values.imeiSearch;
      }
      if (values.needRepairSearch) {
        values.needRepair = values.needRepairSearch;
        delete values.needRepairSearch;
      }
      let params = {
        ...values,
      };
      _exportBatteryList(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              exportload: false,
            });
            window.open(`${res.data.data}`, "_blank").focus();
          } else {
            message.warning(res.data.message);
            this.setState({
              exportload: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  //获取电池型号列表
  getBatteryModelList() {
    let params;
    if (this.state.userType === "0") {
      params = {
        curPage: 1,
        pageSize: 999,
      };
      _batteryModelList(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              batteryModelList: res.data.data.rows,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      params = {
        fid: localStorage.getItem("fid"),
        curPage: 1,
        pageSize: 999,
      };
      _merchantBatModels(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              batteryModelList: res.data.data,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  //获取代理商列表
  getFranchiseeList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _franchiseeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            franchiseeList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取站点列表
  getSiteList(value) {
    let params = {
      fid: value,
      curPage: 1,
      pageSize: 999,
    };
    _siteBoxList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            siteList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //二维码查看
  modalViewQrcode(deviceId, imei) {
    let params = { deviceId, type: "battery" };
    _getQRCode(params)
      .then((res) => {
        if (res.data.code === 200) {
          Modal.info({
            title: `电池二维码 [${imei}]`,
            content: (
              <img src={res.data.data} style={{ width: "100%" }} alt="" />
            ),
            onOk() {},
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //电池操作
  modalViewhandle(text) {
    let self = this;
    let prohibit = null;
    let open = null;
    let instructType = null;
    let used = (text.bindInfo && text.bindInfo.useNo) || text.orderSn;
    if (text.disChargeControl === null) {
      if (text.dischargingStatus === null) {
        prohibit = true;
        open = true;
      } else {
        prohibit = !text.dischargingStatus;
        open = !!text.dischargingStatus;
      }
    } else {
      prohibit = !!text.disChargeControl;
      open = !text.disChargeControl;
    }
    Modal.confirm({
      title: `电池操作 [${text.imei}]`,
      content: (
        <Radio.Group
          onChange={(e) => {
            instructType = e.target.value;
          }}
        >
          <Radio disabled={prohibit} value={1}>
            禁止放电
          </Radio>
          <Radio disabled={open} value={2}>
            打开放电
          </Radio>
        </Radio.Group>
      ),
      onOk() {
        return new Promise(async (resolve, reject) => {
          if (instructType === 2 && !used) {
            Modal.confirm({
              content: "无订单是否确认开电?",
              onOk: async () => {
                await self.batteryControl({
                  id: text.id,
                  instructType,
                });
                resolve();
              },
              onCancel() {
                reject();
              },
            });
          } else {
            await self.batteryControl({
              id: text.id,
              instructType,
            });
            resolve();
          }
        });
      },
    });
  }

  //电池控制
  async batteryControl({ id, instructType }) {
    let params = { id, instructType };
    const res = await _sendInstructionBattery(params);
    if (res.data.code === 200) {
      message.success(res.data.message);
    } else {
      message.warning(res.data.message);
    }
  }

  //经纬度转地址
  async getAddressByPosition(lnglat) {
    return new Promise(async (resolve, reject) => {
      let AMap = await AMapLoader.load({
        key: amapkey,
        version: "2.0",
      });
      AMap.plugin("AMap.Geocoder", function () {
        let geocoder = new AMap.Geocoder();
        geocoder.getAddress(lnglat, function (status, result) {
          console.log(result);
          if (status === "complete" && result.info === "OK") {
            resolve(result.regeocode);
          } else {
            resolve({});
          }
        });
      });
    });
  }

  //查看定位
  modalViewMap({ id, imei }) {
    let params = { id };
    _devicePositionBattery(params)
      .then(async (res) => {
        if (!!res.data.data) {
          let mapPosition = [
            parseFloat(res.data.data.lon),
            parseFloat(res.data.data.lat),
          ];
          const regeocode = await this.getAddressByPosition(mapPosition);
          Modal.info({
            title: `查看定位 [${imei}]`,
            width: "1000px",
            maskClosable: true,
            content: (
              <div style={{ height: 500 }}>
                <Map amapkey={amapkey} zoom={14} center={mapPosition}>
                  <Marker position={mapPosition} />
                  <InfoWindow
                    visible={true}
                    position={mapPosition}
                    offset={[0, -40]}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingBottom: 10,
                        width: 300,
                      }}
                    ></div>
                    <p>最后定位时间：{res.data.data.time}</p>
                    <p>最后定位地址：{res.data.data.address}</p>
                    {/* <p>最后定位地址：{regeocode.formattedAddress}</p> */}
                  </InfoWindow>
                </Map>
              </div>
            ),
          });
        } else {
          message.warning("未查询到该电池的定位信息！");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  handleStatistic({ imei, id }) {
    this.setState({
      statisticVisible: true,
      curImei: imei,
      curId: id,
    });
  }
  handleBoard() {}
  //电池状态Switch
  handleStateChange(id, checked) {
    let params = { id, status: Number(checked) };
    _updateBatteryStatus(params)
      .then((res) => {
        if (res.data.code === 200) {
          message.success("操作成功");
          this.getBatteryList(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues
          );
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // 开启绑定用户弹框
  handleBindOrBackUser(id, kid) {
    if (kid) {
      this.setState({
        backVisible: true,
        curId: id,
      });
    } else {
      this.setState({
        userVisible: true,
        curId: id,
      });
    }
  }
  // 关闭退还弹框
  closeSendBackModal() {
    this.setState({
      backVisible: false,
    });
  }
  // 关闭绑定用户弹框
  closeUserModal() {
    this.setState({
      userVisible: false,
    });
  }
  //单条删除
  handleDeleteChange(id) {
    let self = this;
    Modal.confirm({
      title: "删除信息",
      content: "是否确定删除此数据？",
      onOk() {
        let params = { id };
        _deleteBattery(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              self.getBatteryList(
                self.state.curPage,
                self.state.pageSize,
                self.state.queryFormValues
              );
            }
          })
          .catch((err) => {
            console.log(err);
            message.warning("操作失败");
          });
      },
    });
  }

  //获取电池列表
  getBatteryList(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: curPage,
      pageSize: pageSize,
    };
    _batteryList(params).then((res) => {
      if (res.data.code === 200) {
        if (!res.data.data.rows.length) {
          message.warning("暂无数据！");
        }
        let batteryList = res.data.data.rows;
        this.setState({
          batteryList,
          totalCount: res.data.data.totalCount,
          curPage: curPage,
          selectedRowKeys: [],
        });
        for (let i = 0; i < batteryList.length; i++) {
          const { id } = batteryList[i];
          this.getListStatus(id);
        }
      }
    });
  }
  async getListStatus(id) {
    if (!id) return;
    const res = await _getBatteryStatus({ ids: [id] });
    const { code, data = [] } = res.data || {};
    if (code != 200) return;
    const [row = {}] = data || [];
    const { batteryList } = this.state;
    let bool = false;
    const newList = batteryList.map((item) => {
      if (item.id === row.id) {
        item.chargingStatus = row.chargingStatus;
        item.dischargingStatus = row.dischargingStatus;
        item.disChargeControl = row.disChargeControl;
        item.electricQuantity = row.electricQuantity;
        item.statusName = row.statusName;
        item.chargeStatusName = row.chargeStatusName;
        item.online = row.online;
        bool = true;
      }
      return item;
    });
    if (bool) {
      this.setState({
        batteryList: newList,
      });
    }
  }
  //分页
  handlePageChange(curPage, pageSize) {
    this.getBatteryList(curPage, pageSize, this.state.queryFormValues);
  }

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      // if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      delete values.status;
      if (values.ctTime && values.ctTime.length) {
        values.ctTimeStart = moment(values.ctTime[0]).format("YYYY-MM-DD");
        values.ctTimeEnd = moment(values.ctTime[1]).format("YYYY-MM-DD");
      }
      delete values.ctTime;
      if (values.pushTime && values.pushTime.length) {
        values.pushTimeStart = moment(values.pushTime[0]).format("YYYY-MM-DD");
        values.pushTimeEnd = moment(values.pushTime[1]).format("YYYY-MM-DD");
      }
      delete values.pushTime;
      if (values.statusSearch) {
        values.status = values.statusSearch;
        delete values.statusSearch;
      }
      if (values.modelIdSearch) {
        values.modelId = values.modelIdSearch;
        delete values.modelIdSearch;
      }
      if (values.fidSearch) {
        values.fid = values.fidSearch;
        delete values.fidSearch;
      }
      if (values.sidSearch) {
        values.sid = values.sidSearch;
        delete values.sidSearch;
      }
      if (values.imeiSearch) {
        values.imei = values.imeiSearch;
        delete values.imeiSearch;
      }
      if (values.needRepairSearch) {
        values.needRepair = values.needRepairSearch;
        delete values.needRepairSearch;
      }
      this.setState({
        queryFormValues: values,
      });
      this.getBatteryList(1, this.state.pageSize, values);
    });
  };

  //Modal打开事件（投放）
  async showPushModal() {
    const { selectedRowKeys } = this.state;
    let params = { ids: selectedRowKeys };
    const res = await _getBatteryStatus(params);
    let selectedData = res.data.data || [];
    if (
      selectedData.findIndex((item) => item.orderSn) > -1 ||
      selectedData.findIndex((item) => item.ftype && item.kid) > -1 ||
      selectedData.findIndex((item) => item.pushTime) > -1
    ) {
      return message.warning("您的选择含有不可投放设备请重新选择");
    }
    this.props.form && this.props.form.resetFields();
    this.pushRef.open({ selectedData });
  }
  // 调拨
  async showTransferModal() {
    const { selectedRowKeys } = this.state;
    let params = { ids: selectedRowKeys };
    const res = await _getBatteryStatus(params);
    let selectedData = res.data.data || [];
    if (
      selectedData.findIndex((item) => item.orderSn) > -1 ||
      selectedData.findIndex((item) => item.ftype && item.kid) > -1 ||
      selectedData.findIndex((item) => !item.pushTime && !item.ftype) > -1
    ) {
      return message.warning("您的选择含有不可调拨设备请重新选择");
    }
    this.transferRef.open({ selectedData });
  }
  // 锁电/解锁
  async showLockModal(type) {
    const { selectedRowKeys } = this.state;
    let params = { ids: selectedRowKeys };
    const res = await _getBatteryStatus(params);
    let selectedData = res.data.data || [];
    this.lockRef.current.open({ ids: selectedRowKeys, selectedData, type });
  }
  //已选中数据
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };
  //Modal取消事件（投放）
  initPushFormModal() {
    this.props.form && this.props.form.resetFields();
    this.setState({
      selectedRowKeys: [],
      selectedData: [],
    });
  }

  //Modal打开事件（导出）
  showImportModal() {
    this.importRef.open();
  }
}
BatteryManagement = Form.create({})(BatteryManagement);
export { BatteryManagement };
