import React from "react";
import { Tabs } from "antd";
import DailyPayment from "./DailyPayment";
import MonthlyPayment from "./MonthlyPayment";
import HistoryOverdue from "./HistoryOverdue";
import OverdueSummary from "./OverdueSummary";

const { TabPane } = Tabs;

const Index = () => {
  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="逾期总表" key="1">
        <OverdueSummary />
      </TabPane>
      {localStorage.getItem("type") === "0" && (
        <TabPane tab="日扣逾期" key="2">
          <DailyPayment />
        </TabPane>
      )}
      {localStorage.getItem("type") === "0" && (
        <TabPane tab="月扣逾期" key="3">
          <MonthlyPayment />
        </TabPane>
      )}
      {localStorage.getItem("type") === "0" && (
        <TabPane tab="历史逾期" key="4">
          <HistoryOverdue />
        </TabPane>
      )}
    </Tabs>
  );
};

export default Index;
