import React, { useState } from "react";
import { Row, Col, Form, Button, message, Input, DatePicker } from "antd";
import FranchiseeSelect from "@/components/franchiseeSelect";
import PackageSelect from "@/components/packageSelect";
import SiteSelect from "@/components/siteSelect";
import { _exportAllOverdueReports } from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import moment from "moment";
const userType = localStorage.getItem("type");
var SearchBox = (props) => {
  const { search, form } = props;
  const { getFieldDecorator, setFieldsValue, getFieldValue } = form;
  const [exportload, setExportload] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    let values = form.getFieldsValue();
    const params = getParams(values);
    search(params);
  };
  const handleExportChange = async () => {
    setExportload(true);
    let values = form.getFieldsValue();
    const params = getParams(values);
    const res = await _exportAllOverdueReports(params);
    if (res.data.code === 200) {
      window.open(`${res.data.data}`, "_blank").focus();
    } else {
      message.warning(res.data.message);
    }
    setExportload(false);
  };
  const getParams = (values) => {
    let [createStartTime, createEndTime] = values.createTime || [];
    values.createStartTime = createStartTime
      ? moment(createStartTime).format("YYYY-MM-DD")
      : undefined;
    values.createEndTime = createEndTime
      ? moment(createEndTime).format("YYYY-MM-DD")
      : undefined;
    delete values.createTime;
    let [overdueStartTime, overdueEndTime] = values.overdueTime || [];
    values.overdueStartTime = overdueStartTime
      ? moment(overdueStartTime).format("YYYY-MM-DD")
      : undefined;
    values.overdueEndTime = overdueEndTime
      ? moment(overdueEndTime).format("YYYY-MM-DD")
      : undefined;
    delete values.overdueTime;
    return values;
  };
  return (
    <div>
      <Form onSubmit={handleSubmit} layout="inline">
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="订单号">
              {getFieldDecorator("orderSn")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          {userType === "0" && (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="代理商">
                {getFieldDecorator("fid")(
                  <FranchiseeSelect
                    onChange={() => {
                      setFieldsValue({ sid: "" });
                    }}
                  />
                )}
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="站点">
              {getFieldDecorator("sid")(
                <SiteSelect fid={getFieldValue("fid")} />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="套餐名称">
              {getFieldDecorator("packageId")(<PackageSelect />)}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="订单开始时间">
              {getFieldDecorator("createTime")(
                <DatePicker.RangePicker
                  format="YYYY-MM-DD"
                  style={{ width: "100%" }}
                />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="订单逾期开始时间">
              {getFieldDecorator("overdueTime")(
                <DatePicker.RangePicker
                  format="YYYY-MM-DD"
                  style={{ width: "100%" }}
                />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="用户名/手机号">
              {getFieldDecorator("keyword")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                loading={exportload}
                onClick={handleExportChange}
                style={{ marginLeft: 10 }}
                disabled={!getJurisdiction(1110)}
                title={getJurisdiction(1110) ? "" : "暂无操作权限！"}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

SearchBox = Form.create({})(SearchBox);
export default SearchBox;
